import Image from 'components/Image'
import image from 'images/faq/BFT-Bowl.jpg'
import React from 'react'
import { Link } from 'components/pages/FaqPage'
import ButtonLink from 'components/ui/ButtonLink'

const IsThereAnyWorkOnMyPart = () => (
  <div className="prose prose-md max-w-none">
    <h1>Is There Any Work on My Part When Hiring a Food Truck?</h1>
    <div className="max-w-lg">
      <Image alt="BFT Events" className="shadow rounded-lg" src={image} />
    </div>

    <div className="text-justify">
      <p>
        Best Food Trucks (BFT) works with over a thousand food trucks for
        catering across the country. Whether you’re looking for food trucks in
        Los Angeles, Austin, Portland, D.C., or dozens more cities across the
        country, BFT will find the right trucks for you.
      </p>

      <h3>Examples of Food Trucks We’ve Worked With in Los Angeles</h3>
      <p>
        For example, in{' '}
        <Link href="https://www.bestfoodtrucks.com/food-trucks/los-angeles">
          Los Angeles
        </Link>
        , we’ve worked with food trucks like Cousins Maine Lobster, , The Fix on
        Wheels, Baby’s Bad Ass Burgers, Pickles & Peas Mediterranean, Maravilla
        Cuban-Colombian Fusion, Wise Barbecue, Surfer Tacos,{' '}
        <Link href="https://www.bestfoodtrucks.com/truck/8e8-thai-food-truck">
          8E8 Thai Food Truck
        </Link>
        , and 200+ more.
      </p>
      <h3>Food Trucks and Healthy Eating</h3>
      <p>
        One common misconception that people sometimes have with food truck
        catering is that they only offer unhealthy food. If you’re working in an
        office and you see colleagues head out to the food trucks, you might be
        reluctant to go along if you’re trying to eat healthy, watching
        calories, or have dietary restrictions.
      </p>
      <h3>Booking Food Trucks: a Variety of Options</h3>
      <p>
        When we put together the preference sheets for each location, we take
        into account not only a variety of cuisine, but also make sure there are
        healthy food and “clean” food options. We’re not going to send five taco
        food trucks or cheesesteak trucks to your location in a row, and at the
        same time, we’re not sending only salad trucks either. We work with food
        trucks to make sure they have a variety of options within their menu, so
        each truck has something healthy as well. Just because you’re eating
        from food trucks doesn’t mean you need to sacrifice your gluten-free,
        vegan, or healthy diet.
      </p>
      <h3>How We Customize Your Food Truck Experience</h3>
      <p>
        When you sign up for Best Food Trucks, we’ll work with you to create the
        preference sheet, where you can let us know what kinds of trucks you
        like, or if you have requests for specific trucks that you love. Once we
        start booking food trucks, we’ll collect ratings from customers, which
        will help us learn about your preferences for hiring food trucks for
        weddings or corporate events.
      </p>

      <hr />

      <h3>How BFT Tailors Food Truck Selections to Your Needs?</h3>
      <p>
        At Best Food Trucks, we understand that every event has different food
        requirements. That&apos;s why we customize food truck selections based
        on your needs, whether it&apos;s for a <b>corporate event</b>, a{' '}
        <b>wedding</b>, or a private <b>celebration</b>. With{' '}
        <Link href="https://www.bestfoodtrucks.com/food-trucks-near-me">
          over 1,000 food trucks
        </Link>{' '}
        in our network, we ensure there&apos;s always a perfect match for your
        event. Our team carefully considers your preferences, including cuisine
        types, dietary restrictions, and specific requests, ensuring the best
        possible experience.
      </p>
      <p>
        We work closely with you from the start to create a{' '}
        <b>customized preference sheet</b>, which helps us understand your needs
        in detail. This includes information on the type of food your guests
        would enjoy, any special requirements like gluten-free or halal options,
        and even the type of cuisine you&apos;d like to avoid. Our team then
        curates a list of trucks that match your preferences, ensuring your
        event is a success.
      </p>

      <h3>Healthy Eating Made Easy with BFT</h3>
      <p>
        One of the challenges many people face when considering food trucks is
        the concern over healthy eating. At <b>Best Food Trucks</b>, we take
        this seriously. We partner with trucks that offer a wide range of{' '}
        <b>healthy menu options</b>, including:
      </p>
      <ul>
        <li>
          <b>Vegan and Vegetarian Dishes</b>: From plant-based burgers to
          creative salads, we make sure there are satisfying, nutritious options
          for everyone.
        </li>
        <li>
          <b>Gluten-Free Choices</b>: For those with gluten sensitivities or
          allergies, we ensure there are safe, gluten-free meals available.
        </li>
        <li>
          <b>Clean Eating Options</b>: We work with trucks that specialize in
          low-calorie and low-fat meals, so you don’t have to compromise your
          diet to enjoy a food truck experience.
        </li>
      </ul>

      <h3>Why Customer Feedback Matters?</h3>
      <p>
        At <b>Best Food Trucks</b>, we believe that <b>customer feedback</b> is
        key to improving your food truck experience. After every event, we
        collect ratings and reviews from attendees via SMS, which helps us learn
        more about the trucks you liked and those that may not be the best fit
        for your future events. This feedback allows us to continually improve
        our food truck recommendations, ensuring that every time you work with
        BFT, the experience gets better and more tailored to your preferences.
      </p>
      <p>
        We use this feedback to help optimize our truck selections for events,
        whether you&apos;re{' '}
        <Link href="https://www.bestfoodtrucks.com/blog/how-much-does-catering-cost">
          catering a wedding
        </Link>
        , a <b>corporate meeting</b>, or an
        <b>apartment complex event</b>. Over time, we refine our offerings based
        on real-time data, ensuring that you&apos;re always getting the best
        food trucks that align with your needs.
      </p>
      <h3>How BFT Handles the Logistics when Hiring a Food Truck?</h3>
      <p>
        When you choose <b>Best Food Trucks</b>, we take care of all the
        logistics so you don’t have to. From{' '}
        <Link href="https://www.bestfoodtrucks.com/hire-food-truck">
          booking the food trucks
        </Link>{' '}
        to scheduling, setting up parking spots, and even handling payment
        processing, BFT manages the entire process. This means you can focus on
        enjoying your event without worrying about any of the details. Our team
        coordinates everything with the food truck vendors, ensuring they arrive
        on time, set up smoothly, and provide top-notch service to your guests.
      </p>

      <ButtonLink
        color="primary"
        href="https://www.bestfoodtrucks.com/hire-food-truck"
        variant="contained"
      >
        Yes, I want Food Trucks for my office!
      </ButtonLink>
    </div>
  </div>
)

export default IsThereAnyWorkOnMyPart
