import Image from 'components/Image'
import image from 'images/faq/BFT-CateringOrder.jpg'
import React from 'react'
import { Link } from 'components/pages/FaqPage'

const WhatFoodTruckNeedsForOnlineOrders = () => (
  <div className="prose prose-md max-w-none">
    <h1>What Do I Need to Set Up Online Ordering for My Food Truck?</h1>
    <div className="max-w-lg">
      <Image alt="BFT Events" className="shadow rounded-lg" src={image} />
    </div>

    <div className="text-justify">
      <p>
        We’ve tried here at Best Food Trucks (BFT) to keep the process as simple
        as possible, with minimal requirements so online order-ahead works for
        everyone.
      </p>

      <h3>Devices You’ll Need for Food Truck Online Ordering</h3>
      <p>
        All you’ll need is an{' '}
        <b>
          <Link href="https://itunes.apple.com/us/app/best-food-trucks-bft/id1354908972">
            iOS
          </Link>{' '}
          or{' '}
          <Link href="https://play.google.com/store/apps/details?id=com.bft">
            Android
          </Link>{' '}
          device
        </b>
        , which can be your existing point-of-sale system or a separate tablet
        or phone, and a mobile phone where you can send and receive text
        messages.
      </p>
      <h3>Setting Up the BFT App</h3>
      <p>
        With your iOS/Android tablet or phone, download our BFT app for food
        trucks. This is where incoming order-ahead and online orders will come
        through. It’s just a matter of preference for you whether to use the
        same device as your point-of-sale system or keep another phone on the
        food truck solely for online orders.
      </p>
      <h3>How Order Notifications Work</h3>
      <p>
        When new orders come in, you’ll receive a <b>text message</b> in
        addition to an in-app notification with a loud “DING.” Just make sure
        you have the volume turned up on your device. If you have a credit card
        reader that’s plugged into your headphone jack, we’ll also send you a
        text message to whatever phone number you associate with the shift as a
        backup.
      </p>
      <h3>Why You Need a Mobile Phone for Online Orders</h3>
      <p>
        Lastly, you need a mobile phone so we can send you text message
        notifications. If you have any questions during your shift, you can text
        us, and we’ll respond ASAP. When customers rate your food, you’ll
        receive a text message instantly with their feedback so you can make
        adjustments on the spot. For example, if you didn’t realize you were
        putting on too much tzatziki sauce, you’ll get a notification via text
        with customer feedback.
      </p>
      <h3>No Special Equipment Needed</h3>
      <p>
        You don’t need any other special equipment, and since the app is
        self-sufficient and doesn’t need to be integrated directly into the
        point-of-sale, it will work with whatever setup you currently have.
      </p>

      <h3>Need a Different Setup?</h3>
      <p>
        If your setup requires something different, please reach out to us at{' '}
        <b>support@bestfoodtrucks.com</b>, and we’ll work with you to get you up
        and running.
      </p>
      <h3>How the BFT App Simplifies Food Truck Online Ordering?</h3>
      <p>
        At Best Food Trucks, we understand that food trucks come with various
        setups. That’s why we designed the BFT app to be as flexible as
        possible, ensuring that it fits seamlessly into your existing workflow.
        Whether you’re using a tablet, phone, or traditional point-of-sale
        device, our app works in harmony with your setup. The app’s
        straightforward design allows you to process orders quickly, manage
        incoming requests, and stay updated with customer feedback in real time,
        making sure your operations run smoothly.
      </p>
      <p>
        The in-app notifications and text message backups ensure that you never
        miss an order. We’ve also built in features like real-time feedback from
        customers, so you can adjust your food preparation based on their
        preferences while the shift is ongoing. This level of flexibility gives
        you more control over your operations, enhancing the customer experience
        and improving efficiency.
      </p>
      <h3>Receive Instantaneous Customer Feedback with Text Messages</h3>
      <p>
        The text message system is not just for receiving orders—it’s your
        lifeline for communication during busy shifts. If there’s a technical
        issue, a question about an order, or customer feedback that needs to be
        addressed immediately, texting ensures that you’re never out of the
        loop. You’ll have direct communication with the BFT support team and
        your customers, enabling you to troubleshoot issues and respond to
        feedback in real time.
      </p>
      <p>
        Customer feedback is essential to improving your service, and the BFT
        system sends you these insights instantly. This immediate feedback loop
        gives you a chance to modify dishes mid-shift, providing a unique
        opportunity to adapt to customer preferences on the spot.
      </p>
      <h3>How the BFT App Fits Into Your Current Setup?</h3>
      <p>
        We designed the BFT app to integrate seamlessly with whatever system
        you’re currently using. Whether you have a basic point-of-sale setup or
        a more advanced one, the app runs independently without requiring
        complex integrations. This allows you to maintain your existing workflow
        without disruption. Should you need additional help configuring the app
        for your specific system, our support team is ready to assist you,
        making sure you can use{' '}
        <Link href="https://www.bestfoodtrucks.com/trucks">
          food truck online ordering
        </Link>{' '}
        with as little hassle as possible.
      </p>
    </div>
  </div>
)

export default WhatFoodTruckNeedsForOnlineOrders
