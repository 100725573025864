import Image from 'components/Image'
import image from 'images/faq/BFT-BabyBadAssBurger.jpg'
import React from 'react'
import { Link } from 'components/pages/FaqPage'
import ButtonLink from 'components/ui/ButtonLink'

const WhichFoodTrucksYouWorkWith = () => (
  <div className="prose prose-md max-w-none">
    <h1>Which Catering Food Trucks Do You Work With?</h1>
    <div className="max-w-lg">
      <Image alt="BFT Events" className="shadow rounded-lg" src={image} />
    </div>

    <div className="text-justify">
      <p>
        Best Food Trucks (BFT) has partnered with food trucks across many U.S.
        cities, including Los Angeles, Austin, Portland, D.C., and dozens more.
        So trust us when we say we have just the right{' '}
        <Link href="https://www.bestfoodtrucks.com/hire-food-truck">
          food trucks to cater your events
        </Link>{' '}
        — corporate, weddings, or private celebrations.
      </p>
      <h3>Example of Food Trucks in Los Angeles</h3>
      <p>
        For example, in Los Angeles, we’ve worked with food trucks like Cousins
        Maine Lobster, The Grilled Cheese Truck, Baby’s Badass Burgers, Pickles
        & Peas Mediterranean, Maravilla Cuban-Colombian Fusion, Roll’n Lobster,
        Surfer Tacos, Dogtown Dogs, and 200+ more.
      </p>
      <h3>A Variety of Cuisines Including Healthy Food Trucks</h3>
      <p>
        However, many people believe that food trucks cater only unhealthy food,
        which dissuades them from visiting one to maintain a healthy diet.
      </p>
      <p>
        BFT goes the extra mile to make sure our food trucks not only have a
        variety of cuisines but that there are also healthy and “clean” food
        options. We’re not going to send five taco food trucks or the same local
        food trucks to your location in a row, and at the same time, we’re not
        sending only salad trucks either. We work with food trucks to make sure
        they have a variety of options within their food truck menu, so each
        truck has something that’s healthy as well. Just because you’re eating
        from food trucks doesn&apos;t mean you need to sacrifice your
        gluten-free, vegan, or just healthy diet. We also offer halal food
        trucks.
      </p>
      <h3>Customizing Your Food Truck Experience</h3>
      <p>
        When you choose Best Food Trucks, we’ll work with you to know what kinds
        of trucks you like, or if you have requests for specific trucks that you
        love. Once we start booking food trucks, we’ll collect ratings from
        customers, which will help us learn which trucks you love, and which
        ones are better suited for other spots.
      </p>

      <hr />
      <h3>How BFT Ensures a Perfect Match for Your Event?</h3>
      <p>
        At Best Food Trucks, we go beyond just selecting any available food
        trucks. We carefully curate trucks based on their cuisine, popularity,
        and customer ratings to ensure the perfect match for your event. We
        understand that your event’s success heavily depends on the food options
        you provide, which is why we work with trucks that offer a wide range of
        cuisines, including gourmet, comfort food, and healthier alternatives.
        Whether you’re hosting a corporate lunch, a wedding reception, or a
        community event, we ensure that the food truck lineup reflects the
        preferences and dietary needs of your guests.
      </p>

      <p>
        Our partnerships span over <b>1,000+ food trucks nationwide</b>, which
        allows us to meet your exact requirements, from high-end lobster rolls
        to hearty burgers. We not only offer variety but also guarantee the
        trucks are known for their <b>reliable service and high-quality food</b>
        .
      </p>

      <h3>How We Cater to Dietary Preferences and Restrictions?</h3>
      <p>
        In addition to offering popular comfort foods, BFT works with food
        trucks that cater to a range of dietary preferences. We understand that
        some guests may be looking for gluten-free, vegan, or vegetarian
        options, and we ensure these choices are available without compromising
        on taste or quality. <b>Halal food truck options</b> are also part of
        our diverse truck network, making us capable of catering to religious
        and dietary restrictions.
      </p>
      <p>
        To ensure that each truck provides options for everyone, we take extra
        measures to check their menus beforehand. For example, if you’re
        organizing an event with specific dietary needs, we can coordinate with
        trucks that specialize in clean eating, ensuring there are healthy
        choices like salads, wraps, or plant-based dishes.
      </p>

      <h3>How the Customer Feedback Loop Works?</h3>
      <p>
        Once we’ve booked food trucks for your event, BFT doesn’t just stop
        there. We actively collect feedback from your guests through SMS
        surveys, allowing us to gather real-time insights into which trucks were
        a hit and which ones didn’t meet expectations. This feedback is
        invaluable as it helps us refine our future selections and ensure that
        only the best trucks make a return visit. Your satisfaction is at the
        heart of everything we do, and our goal is to improve the experience
        with every booking.
      </p>
      <p>
        Feedback data is stored and analyzed so that over time, BFT becomes even
        more tailored to your company or event&apos;s preferences. We can
        recommend trucks based on previous events or introduce you to new food
        trucks that have garnered high ratings from customers in similar
        settings. Our <b>customer feedback loop</b> ensures that your experience
        with BFT only gets better over time.
      </p>

      <h3>Why Choose BFT for Your Catering Needs?</h3>

      <p>
        When you work with BFT, you’re not just getting access to food trucks,
        you’re gaining a partner that takes care of all the logistics. We handle
        everything from <b>initial booking</b> and <b>coordination</b> to{' '}
        <b>feedback collection</b> and <b>follow-up services</b>. Our network of
        trusted food trucks is carefully vetted for quality, reliability, and
        service, ensuring your guests have an unforgettable experience with food
        that fits the occasion.
      </p>
      <p>
        Whether you need{' '}
        <Link href="https://www.bestfoodtrucks.com/blog/how-much-does-catering-cost">
          food trucks for a corporate event
        </Link>
        , a wedding, or a festival, BFT can handle it all with ease. We make
        sure you get a<b>varied and exciting selection</b> of food trucks
        without the hassle of managing multiple vendors yourself. From the
        moment you request a truck to the final plate being served, BFT manages
        the process so that your event runs smoothly.
      </p>

      <ButtonLink
        color="primary"
        href="https://www.bestfoodtrucks.com/hire-food-truck"
        variant="contained"
      >
        Yes, I want Food Trucks for my office!
      </ButtonLink>
    </div>
  </div>
)

export default WhichFoodTrucksYouWorkWith
