import Image from 'components/Image'
import image from 'images/faq/BFT-Lunch.jpg'
import React from 'react'
import { Link } from 'components/pages/FaqPage'
import ButtonLink from 'components/ui/ButtonLink'

const WhatIfWeAlreadyHaveFoodTrucks = () => (
  <div className="prose prose-md max-w-none">
    <h1>What If We Already Have Food Trucks for Company Lunches?</h1>
    <div className="max-w-lg">
      <Image alt="BFT Events" className="shadow rounded-lg" src={image} />
    </div>

    <div className="text-justify">
      <p>
        If you already hire a food truck for your daily{' '}
        <Link href="https://www.bestfoodtrucks.com/faq/catering/how-do-i-book-food-trucks-for-my-office">
          office lunch catering
        </Link>
        , that means you also already have lines during lunch, no way for people
        to order and see photo menus, no schedule, and no ratings feedback
        collected by customers. This means there’s no iteration based on what
        people actually like.
      </p>

      <h3>Common Issues with Existing Food Trucks for Company Lunches</h3>
      <p>
        When office buildings switch over to us, we typically see the same setup
        in most cases. Either there’s no dedicated page that shows an upcoming
        schedule, or an email has to be sent out daily, or there’s a Google
        calendar that has names of trucks but no menus, or basic flyers for food
        trucks that might have a menu that is months old and severely outdated
        (as food trucks change their menus frequently).
      </p>
      <h3>How BFT Improves Food Truck Catering</h3>
      <p>
        At Best Food Trucks (BFT), we receive ratings every day from every lot
        where we’re active. We use this feedback to iterate and improve the
        selection to ensure everyone is happy. Traditional food truck companies
        have virtually zero relationship with your people, so they have no idea
        what people like or don’t like, and thus they’re stuck sending the same
        trucks over and over.
      </p>
      <p>
        In addition, we have a dedicated support team working around the clock
        via text message. When people have questions or need help with their
        order, we respond promptly.
      </p>
      <h3>Why Order Ahead Is a Game-Changer</h3>
      <p>
        But most importantly, why would anyone want to wait in line at the truck
        when they could order ahead and pick up their food when it’s ready? The
        biggest reason why office buildings switch to Best Food Trucks (BFT) is
        that they want their employees or tenants to enjoy their lunch hour and
        not waste it standing in line.
      </p>
      <p>
        When customers order online from our available food truck menu, they can
        get more work done or simply relax and enjoy their lunch hour talking to
        friends instead of standing in place for 20-30 minutes.
      </p>
      <h3>The BFT Difference: Make Lunchtime Efficient and Enjoyable</h3>
      <p>
        In other words, the difference is night and day. If you have office
        lunch catering via food trucks already, let us help make your
        employees&apos; lives easier. Sign up today, and we’ll keep any food
        trucks you already have scheduled and turn on an online lunch ordering
        system for your offices so people can start enjoying the benefits
        immediately.
      </p>
      <p>
        We’ll also handle all the logistics of switching food truck vendors, so
        you don’t have to lift a finger.
      </p>
      <h3>Happier Employees and Greater Efficiency</h3>
      <p>
        Plus, when you start getting emails from happy people thanking you for
        enabling order ahead and allowing them to take back their lunch hour,
        you’ll wonder why you ever had people wait in line in the first place.
      </p>
      <h3>
        How BFT Eliminates Common Pain Points in Traditional Food Truck Setups?
      </h3>
      <p>
        Many workplaces with existing food truck setups struggle with
        inefficiencies. The absence of a dedicated scheduling platform often
        leads to poor communication, outdated menus, and a lack of transparency
        for employees about which trucks are coming. Flyers and Google calendars
        don’t provide the seamless experience employees expect. With BFT, these
        issues are solved instantly.
      </p>
      <p>
        Our system ensures that the schedule and menus are updated in real-time,
        providing employees with easy access to their favorite trucks and
        dishes. No more guessing which truck is coming or dealing with outdated
        menus—everything is streamlined through our platform.
      </p>
      <h3>Importance of Customer Feedback for Better Service</h3>
      <p>
        Traditional food truck setups often lack direct customer feedback, which
        results in food trucks showing up with no knowledge of whether their
        menu is well-received or if changes need to be made. Best Food Trucks
        integrates a feedback loop that helps us constantly refine the selection
        based on employee preferences.
      </p>
      <p>
        When employees rate their meals through our platform, we gather valuable
        insights about which food trucks are most popular and which may need
        adjustments. This allows us to ensure that only the top-rated trucks
        return, keeping everyone satisfied with the options.
      </p>
      <h3>How BFT Streamlines Food Truck Vendor Management?</h3>
      <p>
        Managing food trucks at scale can be a logistical nightmare for office
        buildings and property managers. BFT takes care of all vendor logistics,
        from handling contracts and scheduling to managing feedback and
        resolving any issues. Whether you’re introducing new trucks or
        maintaining existing ones, BFT makes the process seamless.
      </p>
      <p>
        Our online ordering system also eliminates the need for employees to
        carry cash or wait at the truck. They can browse menus, pay online, and
        pick up their food when it’s ready, ensuring a stress-free lunch hour.
      </p>
      <h3>Switch to BFT to Get Food Trucks to Your Office</h3>
      <p>
        <Link href="https://www.bestfoodtrucks.com/hire-food-truck">
          Hiring a food truck
        </Link>{' '}
        through Best Food Trucks for office lunch catering is easy and comes
        with numerous benefits. Not only do we maintain any existing
        relationships with your current food trucks, but we also enhance the
        experience by offering online ordering and a more efficient way to
        manage lunch breaks. With our system in place, employees and tenants can
        enjoy their breaks without the hassle of long lines, outdated menus, or
        poorly managed schedules.
      </p>
      <p>
        Once you make the switch, you’ll start hearing from grateful employees
        who appreciate the ability to order ahead and avoid the lunch rush. The
        efficiency gained from our platform helps improve workplace productivity
        while also keeping everyone satisfied.
      </p>

      <ButtonLink
        color="primary"
        href="https://www.bestfoodtrucks.com/hire-food-truck"
        variant="contained"
      >
        Yes, I want Food Trucks for my office!
      </ButtonLink>
    </div>
  </div>
)

export default WhatIfWeAlreadyHaveFoodTrucks
