import React from 'react'
import image from 'images/faq/BFT-OfficeCatering.jpg'
import Image from 'components/Image'
import { Link } from 'components/pages/FaqPage'
import ButtonLink from 'components/ui/ButtonLink'

const HowToBookFoodTrucksForYourOffice = () => (
  <div className="prose prose-md max-w-none">
    <h1>How to Book Food Trucks for Your Office: A Quick Guide</h1>
    <div className="max-w-md">
      <Image alt="Office catering" className="shadow rounded-lg" src={image} />
    </div>
    <div className="text-justify">
      <p>
        We get it. You’re sick of going to the same three restaurants over and
        over. Delivery food comes lukewarm at best, meaning say goodbye to
        crispy french fries, and get used to using words like “soggy” and
        “stale.” Catering trays give you little choice, and 25% of that food is
        likely going into the trash.
      </p>

      <h3>Why Food Trucks Are the Solution for Office Lunch Catering</h3>
      <p>
        That’s where food trucks come in.{' '}
        <Link href="https://www.bestfoodtrucks.com/faq/orderahead/how-does-order-online-work">
          Food trucks that will come to your work
        </Link>{' '}
        are the best solution to bring a professional chef and a kitchen just
        steps from your office. No soggy fries. No long wait times.
      </p>

      <h3>Best Food Trucks (BFT) Offers Online Ordering for Food Trucks</h3>
      <p>
        Best Food Trucks (BFT) also provides a{' '}
        <Link href="https://www.bestfoodtrucks.com/faq/orderahead/how-does-order-online-work">
          food truck online ordering system
        </Link>{' '}
        for all our corporate lunch trucks, so you never have to wait in line.
        When your food is ready, you’ll receive a text message so you can come
        grab it fresh. No lines mean you can get more work done, relax more, or
        become your office hero.
      </p>

      <h3>Why Offices Prefer Food Trucks Over Traditional Catering</h3>
      <p>
        This is why many offices are shifting towards corporate catering food
        trucks rather than traditional catering dishes. You get all the
        convenience of online ordering with the freshest food made right outside
        your doorstep.
      </p>

      <h3>Challenges of Booking Food Trucks Without BFT</h3>
      <p>
        Typically, offices would have to manage this whole process
        themselves—negotiating minimums, checking certifications and licenses,
        tracking bookings, managing and tracking payouts, and routing customer
        feedback to the truck. Sounds fun, right?
      </p>

      <h3>Why BFT Makes Booking Food Trucks Effortless</h3>
      <p>
        BFT handles all of this, <b>for free</b>. We make sure you&apos;re happy
        with your office food truck experience. Our unique lunch ordering system
        and decade-long relationships with food trucks in your area make the
        process smooth and stress-free. You’ll never dread the “What do you want
        to do for lunch?” question again.
      </p>

      <hr />

      <h3>Step-by-Step Guide to Booking a Food Truck with BFT</h3>
      <p>
        Booking a food truck with Best Food Trucks is incredibly easy and
        stress-free. Here’s a simple guide to help you understand the process:
      </p>
      <ol>
        <li>
          <b>Submit Your Request</b>: Start by providing your office location,
          date, and number of employees through our BFT platform. You can even
          request specific cuisines or dietary preferences.
        </li>
        <li>
          <b>Review the Food Truck Options</b>: Once we receive your request,
          we’ll provide a list of available food trucks, complete with their
          menus, customer ratings, and pricing details.
        </li>
        <li>
          <b>Confirm Your Booking</b>: After reviewing your options, simply
          confirm your booking with us, and we’ll handle the rest. From
          scheduling to organizing parking spots, we take care of all logistics.
        </li>
        <li>
          <b>Employees Place Their Orders Online</b>: With our{' '}
          <b>online ordering system</b>, your employees can place their lunch
          orders ahead of time. They’ll get a text message when their food is
          ready, making the process seamless.
        </li>
        <li>
          <b>Enjoy Fresh, Hot Meals</b>: Food is prepared fresh on-site,
          ensuring your team enjoys delicious meals without long waits or soggy
          deliveries.
        </li>
      </ol>

      <h3>How BFT Ensures Variety in Your Office Lunches?</h3>
      <p>
        At BFT, we understand that variety is essential when it comes to office
        lunches. That’s why we work with a wide range of food trucks offering
        everything from classic American burgers to international cuisines like
        Mediterranean, Mexican, and Asian fusion. We also ensure that trucks
        cater to dietary needs such as gluten-free, vegan, and halal.
      </p>
      <p>
        By rotating food trucks regularly, BFT ensures that your team doesn’t
        see the same options week after week. This keeps lunches exciting and
        gives your employees something to look forward to.
      </p>

      <h3>Benefits of Using Food Trucks for Corporate Catering</h3>
      <p>
        Switching to{' '}
        <Link href="https://www.bestfoodtrucks.com/hire-food-truck">
          food trucks for office catering
        </Link>{' '}
        has several benefits:
      </p>
      <ol>
        <li>
          <b>Fresher Food</b>: Food is made fresh on-site, eliminating the
          sogginess that often comes with delivery or catering trays.
        </li>
        <li>
          <b>Reduced Wait Time</b>: Our <b>order-ahead technology</b> ensures
          that employees don’t need to wait in line, allowing them more time to
          relax or focus on work.
        </li>
        <li>
          <b>Increased Employee Satisfaction</b>: Offering a diverse and
          high-quality meal experience can boost office morale and give
          employees something fun to talk about.
        </li>
        <li>
          <b>Hassle-Free Logistics</b>: With BFT managing everything from truck
          booking to payment processing, office managers can relax and enjoy the
          event without worrying about the details.
        </li>
      </ol>

      <hr />

      <h3>Frequently Asked Questions (FAQ)</h3>
      <ol>
        <li>
          <b>How far in advance should we book a food truck?</b>
          <ul>
            <li>
              It’s best to book at least <b>one week in advance</b> for regular
              office lunches and <b>two weeks in advance</b> for larger events
              to ensure availability.
            </li>
          </ul>
        </li>

        <li>
          <b>Can BFT cater to specific dietary needs?</b>
          <ul>
            <li>
              Yes! BFT partners with trucks that offer{' '}
              <b>gluten-free, vegan, vegetarian</b>, and <b>halal</b> options,
              ensuring that every dietary preference is covered.
            </li>
          </ul>
        </li>

        <li>
          <b>Do you offer trucks for regular recurring lunches?</b>
          <ul>
            <li>
              Absolutely! We can arrange for <b>weekly, bi-weekly</b>, or even{' '}
              <b>monthly</b> food truck visits to your office. Our system allows
              for easy scheduling, and we rotate trucks to keep the meals fresh
              and exciting.
            </li>
          </ul>
        </li>
      </ol>

      <ButtonLink
        color="primary"
        href="https://www.bestfoodtrucks.com/hire-food-truck"
        variant="contained"
      >
        Yes, I want Food Trucks for my office!
      </ButtonLink>
    </div>
  </div>
)

export default HowToBookFoodTrucksForYourOffice
